import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamationCircle } from '@fortawesome/pro-light-svg-icons'
import { ButtonContainer, ConfirmButton, Error, InputBox } from '../../../../styles'

export default function NewUserForm(props) {
  const [payload, setPayload] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState('stop')

  useEffect(() => {
    setPayload({ ...props.payload })
  }, [props.payload])

  function handleChange(e) {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value,
    })
  }

  function validatePayload() {
    if (!payload.name) {
      setErrorMessage('Por favor, insira o nome do usuário.')
      return false
    }

    if (!payload.email) {
      setErrorMessage('Por favor, insira o e-mail do usuário.')
      return false
    }

    if (!/escolaconquer.com.br/.test(payload.email) && !/wisereducacao.com/.test(payload.email)) {
      setErrorMessage('O email precisa ser escolaconquer ou wisereducacao')
      return false
    }

    setErrorMessage('')
    return true
  }

  function handleEnter(e) {
    if (e.keyCode !== 13) return
    handleSubmit()
  }

  function handleSubmit() {
    if (!validatePayload()) return

    setLoading('loading')
    const newUser = {
      name: payload.name,
      email: payload.email,
    }

    axios
      .post('/admin/user', newUser)
      .then((res) => {
        props.showAlert({
          message: <span>Usuário cadastrado com sucesso!</span>,
          success: true,
        })
        props.updateData(true)
        setSuccess(true)
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.msg || 'Internal Server Error')
      })
      .finally(() => {
        setLoading('stop')
      })
  }

  return (
    <>
      {payload && (
        <>
          <InputBox>
            <label>Nome do usuário</label>
            <input
              name='name'
              type='text'
              placeholder='Nome'
              value={payload.name}
              onChange={handleChange}
              disabled={success}
              onKeyDown={(e) => handleEnter(e)}
            />
          </InputBox>

          <InputBox>
            <label>E-mail</label>
            <input
              name='email'
              type='text'
              placeholder='E-mail'
              value={payload.email}
              onChange={handleChange}
              disabled={success}
              onKeyDown={(e) => {
                handleEnter(e)
              }}
            />
          </InputBox>

          {success && (
            <InputBox>
              <label>
                <p>
                  {' '}
                  <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }}></FontAwesomeIcon> Um
                  email foi enviado para <b>{payload.email}</b> com instruções para realizar o
                  primeiro acesso
                </p>
              </label>
            </InputBox>
          )}

          {errorMessage && (
            <Error>
              <FontAwesomeIcon icon={faExclamationCircle} />
              {errorMessage}
            </Error>
          )}
          <ButtonContainer>
            <div></div>
            <ConfirmButton onClick={() => handleSubmit()} disabled={loading !== 'stop' || success}>
              <span>{loading === 'stop' ? 'Confirmar' : 'Processando'}</span>
              <FontAwesomeIcon icon={faCheck} />
            </ConfirmButton>
          </ButtonContainer>
        </>
      )}
    </>
  )
}
