import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { ActionButton, Container, HeaderSpacer, TableHeader } from '../../styles'
import ConquerTable from '../../Template/ConquerTable'

import { faCopy, faEyes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from '../../Template/Modal'
import { WinnersContainer } from './styles'

export default function RankingAdmin() {
  const [data, setData] = useState([])
  const [loadingTable, setLoadingTable] = useState(true)
  const [loadingTableWinners, setLoadingTableWinners] = useState(true)
  const [showWinnerModal, setShowWinnerModal] = useState(false)
  const [winners, setWinners] = useState([])

  let isMounted = true

  const columns = React.useMemo(() => [
    { title: 'Ranking', field: 'ranking_id' },
    {
      title: 'Data de inicio',
      field: 'start_at',
      content: (v) => new Date(v.start_at).toLocaleDateString('pt-BR'),
    },
    {
      title: 'Data de fim',
      field: 'end_at',
      content: (v) => new Date(v.start_at).toLocaleDateString('pt-BR'),
    },
    {
      title: 'Status',
      field: 'state',
      content: (v) => (v.state === 1 ? 'Criado' : v.state === 2 ? 'Iniciado' : 'Finalizado'),
    },
    {
      title: 'Tipo',
      field: 'tipe',
      content: (v) => (v.tipe === 1 ? 'Semanal' : v.tipe === 2 ? 'Mensal' : 'Quinzenal'),
    },
    {
      title: 'Visualizar ganhadores.',
      content: (v) => (
        <ActionButton
          onClick={() =>
            handleVisualizeWinners({
              ranking_id: v.ranking_id,
            })
          }
        >
          <FontAwesomeIcon icon={faEyes} />
        </ActionButton>
      ),
      width: 50,
    },
  ])

  const columnsWinner = React.useMemo(() => [
    { title: 'Posição', field: 'position' },
    { title: 'Nome do estudante', field: 'studentName' },
    {
      title: 'Copiar email do estudante',
      content: (v) => (
        <ActionButton onClick={() => getStudentEmail(v)}>
          <FontAwesomeIcon icon={faCopy} />
        </ActionButton>
      ),
    },
  ])

  async function getData() {
    setLoadingTable(true)
    await axios
      .get('/admin/gamification/rankings')
      .then((res) => {
        setData(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoadingTable(false)
      })
  }

  async function getStudentEmail(v) {
    await axios
      .get(`/admin/student/${v.studentId}/details`)
      .then((res) => {
        navigator.clipboard.writeText(res.data.email)
      })
      .catch(() => {
        alert(
          'não foi possivel recuperar o email do aluno. Por favor, entre em contato com o suporte.',
        )
      })
  }

  async function handleVisualizeWinners(v) {
    setLoadingTableWinners(true)
    await axios
      .get(`/admin/gamification/ranking/${v.ranking_id}/winners`)
      .then((resp) => {
        setWinners(resp.data)
        setShowWinnerModal(true)
      })
      .finally(() => {
        setLoadingTableWinners(false)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Container>
        {showWinnerModal && (
          <Modal title='Visualizar ganhadores' close={() => setShowWinnerModal(false)} width={50}>
            <WinnersContainer>
              <h3>{!winners && 'Nenhum ganhador encontrado'}</h3>
              {winners && (
                <TableHeader>
                  <ConquerTable
                    columns={columnsWinner}
                    data={winners}
                    loading={loadingTableWinners}
                    disablePagination={true}
                  />
                </TableHeader>
              )}
            </WinnersContainer>
          </Modal>
        )}
        <>
          <TableHeader>
            <span>Novidades</span>
            <div>
              <HeaderSpacer />
            </div>
          </TableHeader>
          <ConquerTable
            columns={columns}
            data={data}
            loading={loadingTable}
            disablePagination={true}
          />
        </>
      </Container>
    </>
  )
}
